@import "~easytecq-css/scss/functions";
@import "~easytecq-css/scss/button";
@import "~easytecq-css/scss/input";
@import "~easytecq-css/components/linkList";
@import "~easytecq-css/components/game";
@import "~easytecq-css/scss/placeholder";
@import "~easytecq-css/components/sectionTitle";
@import "~easytecq-css/components/contentCard";
@import "easytecq-css/components/navPage";
@import "easytecq-css/components/sportsbook";
@import "~easytecq-css/scss/form";

@media (max-width: 499px) {
    .eo-form{
        width: 100%;
    }
}

@media (min-width: 500px) {
    .eo-form {
        width: 480px;
    }
}

.register-align {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.eo-pageTitle {
    padding-left: var(--space-1_5x);
    padding-right: var(--space-1_5x);
    max-width: 480px;
    margin: 0 auto;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    flex: 1 1 0;
    width: 100%;
}